<template>    
  <div v-if="!$route.meta.hideNavbar">
    <nav-bar/>
    <div style="margin-top: 120px;">
      <router-view class="mt-5"/>
    </div>
  </div>
  <div v-else>
    <router-view class="mt-5"/>
  </div>  
</template>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
</style>

<script>

import NavBar from "@/components/Navbar.vue";

export default {
    name: 'App',
    components: {NavBar}
}
</script>