<template>
    <div class="container">
        <div v-if="isLoading === true">
            <div class="containermb-5">
                <div class="row">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="centered-container">
                                <div class="loading"></div>
                                <p class="text-font" style="margin-top: 10px; text-align: center">
                                    Carregando...
                                </p>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <!-- Termo -->
            <div v-if="currentValue == 0">
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-8">
                        <h2 class="style-font-bold">Termo de Responsabilidade</h2>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Eu {{ User.name }}, enfermeiro(a), declaro que esta solicitação de encaminhamento para
                        teleinterconsulta médica especializada, foi autorizada pelo profisional médico(a) responsável
                        pelo atendimento do(a) paciente.
                    </p>
                    <p class="justified-text">
                        Declaro também, que este(a) profissional médico(a) possui total ciência da presente solicitação
                        de Interconsulta médica especializada que está sendo solicitada em seu nome.
                    </p>
                </div>
                <div class="row mt-2">
                    <div class="col-2"></div>
                    <div class="col-8">
                        <div class="form-check d-flex justify-content-center align-items-center">
                            <input type="checkbox" style="margin-right: 10px;" class="form-check-input"
                                id="termsFormAssistence" v-model="termsFormAssistence">
                            <label class="form-check-label justified-text" for="termsFormAssistence">Li, concordo e
                                aceito
                                todos os termos e condições de uso.</label>
                        </div>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="row flow-down ">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="redirectStepInTerm">PRÓXIMO</button>
                    </div>
                </div>
            </div>
            <!-- Dados Medico: Preencher Cadastro Medico Responsavel -->
            <div v-if="currentValue == 1">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Preencha os dados do médico responsável para efetuar o cadastro:
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="crm">Digite o número de CRM do
                            responsável:</label>
                        <input class="input-field" type="text" v-model="crm" id="crm" name="crm" maxlength="7"
                            placeholder="0000000" v-mask="'#######'">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="selectedState">Selecione a UF do
                            documento:</label>
                        <v-select class="mt-2" v-model="selectedState" :options="states" label="name" id="state">
                            <template v-slot:no-options>
                                <span>Nenhum estado encontrado</span>
                            </template>
                        </v-select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="phone">Digite o celular do responsável
                            (Opcional):</label>
                        <input class="input-field" type="text" v-model="phone" id="phone" name="phone" maxlength="14"
                            placeholder="(00) 00000-0000" v-mask="'(##) #####-####'">
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="validateCFM()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Febre-->
            <div v-if="currentValue == 2">
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Febre</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <p class="justified-text">
                        O paciente apresentou FEBRE nas últimas 24 horas?:
                    </p>
                </div>
                <div class="row mt-3" style="cursor: pointer">
                    <div class="border-in" @click="handleFeverClick(true, 4)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/ic_posit_s.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">SIM</h4>
                            <p class="style-font" style="margin: 0;">O paciente apresentou febre</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="handleFeverClick(false, 4)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/ic_negat_n.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Não</h4>
                            <p class="style-font" style="margin: 0;">O paciente não apresentou febre</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Glicemia -->
            <div v-if="currentValue == 3">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Glicemia</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <p class="justified-text">
                        A Glicemia Capilar da(o) paciente esteve abaixo de 50 mg/dl ou ultrapassou 180 mg/dL nas últimas
                        24 horas?:
                    </p>

                </div>
                <div class="row mt-3" style="cursor: pointer">
                    <div class="border-in" @click="handleGlicemiaClick('ABAIXO', 4)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/ic_posit_up.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">&lt;50</h4>
                            <p class="style-font" style="margin: 0;">Glicemia abaixo de 60 mg/dl</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="handleGlicemiaClick('ACIMA', 4)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/ic_negat_down.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">&gt;180</h4>
                            <p class="style-font" style="margin: 0;">Glicemia ultrapassou 180 mg/dl</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="handleGlicemiaClick('Não', 4)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/ic_negat_et.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Não</h4>
                            <p class="style-font" style="margin: 0;">Glicemia não apresentou variação</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Frequencia Cardiaca -->
            <div v-if="currentValue == 4">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Frequência Cardíaca</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <p class="justified-text">
                        Qual é a FC (Frequência Cardíaca)
                        média das últimas 24 horas da(o)
                        paciente?:
                    </p>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="freqbpm">Informe a frequência:</label>
                        <input class="input-field" type="text" v-model="freqbpm" id="freqbpm" name="freqbpm"
                            maxlength="3" placeholder="000 bpm" @input="validateFreq">
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="redirectFreqC(5)">SEGUIR</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Balanço Hídrico -->
            <div v-if="currentValue == 5">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Balanço Hídrico</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <p class="justified-text">
                        Escreva qual o valor em ml do balanço
                        hídrico nas últimas 24h? (exemplo de
                        resposta: 200 ml):
                    </p>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="hydrologicalBalance">Informe o balanço
                            hídrico:</label>
                        <input class="input-field" type="text" v-model="hydrologicalBalance" id="hydrologicalBalance"
                            name="hydrologicalBalance" maxlength="5" placeholder="00000 ml"
                            @input="validateHydrologicalBalance">
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="redirectBalance(6)">SEGUIR</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Ventilação Mecânica-->
            <div v-if="currentValue == 6">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Ventilação Mecânica</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <p class="justified-text">
                        Paciente está em VM (Ventilação Mecânica)?
                    </p>
                </div>
                <div class="row mt-3" style="cursor: pointer">
                    <div class="border-in" @click="handleMechanicalVentilation(true, 7)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/ic_posit_s.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">SIM</h4>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="handleMechanicalVentilation(false, 8)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/ic_negat_n.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Não</h4>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Tipo de ventilação Mecânica -->
            <div v-if="currentValue == 7">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Tipo de ventilação Mecânica</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <p class="justified-text">
                        Selecione o tipo de ventilação mecânica que o paciente está utilizando:
                    </p>

                </div>
                <div class="row mt-3" style="cursor: pointer">
                    <div class="border-in" @click="handleTypeOfVentilation('PCV', 10)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/pcv.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Ventilação com pressão controlada</h4>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="handleTypeOfVentilation('VCV', 10)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/vcv.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Ventilação controlada a volume</h4>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="handleTypeOfVentilation('PSV', 10)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/psv.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Ventilação por pressão de suporte</h4>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Respiração -->
            <div v-if="currentValue == 8">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Respiração</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <p class="justified-text">
                        Selecione a opção de respiração do paciente:
                    </p>
                </div>
                <div class="row mt-3" style="cursor: pointer">
                    <div class="border-in" @click="handleBreathingType('AR', 10)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/ic_posit.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Ar ambiente</h4>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="handleBreathingType('SUPORTE', 9)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/ic_sup_o2.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Suporte O2</h4>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Suporte O2 -->
            <div v-if="currentValue == 9">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Suporte O2</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <p class="justified-text">
                        Escreva qual tipo de suporte O2 que o
                        paciente está recebendo:
                    </p>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="supportTypeO2">Tipo de suporte O2:</label>
                        <input class="input-field" type="text" v-model="supportTypeO2" id="supportTypeO2"
                            name="supportTypeO2" placeholder="Suporte O2">
                    </div>
                </div>

                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="redirectStep(10)">SEGUIR</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Droga Vasoativa-->
            <div v-if="currentValue == 10">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Droga Vasoativa</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <p class="justified-text">
                        Paciente está usando droga vasoativa?
                    </p>
                </div>
                <div class="row mt-3" style="cursor: pointer">
                    <div class="border-in" @click="handleVasoactiveDrug(true, 11)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/ic_posit_s.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">SIM</h4>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="handleVasoactiveDrug(false, 12)"
                        style="display: flex; align-items: center;">
                        <img src="../assets/ic_negat_n.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Não</h4>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Tipo Droga Vasoativa-->
            <div v-if="currentValue == 11">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Tipo de Droga Vasoativa</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <p class="justified-text">
                        Quais as drogas vasoativas em uso?
                    </p>
                </div>
                <div v-for="(item, index) in checkboxItems" :key="index" class="row mt-3" style="cursor: pointer">
                    <div class="border-in" style="display: flex; align-items: center;">
                        <div style="display: flex; align-items: center; flex-direction: column;">
                            <div style="display: flex; align-items: center;">
                                <input type="checkbox" :id="item.id" :value="item.label" v-model="selectedCheckboxes" />
                                <label :for="item.id" class="style-font" style="margin-left: 10px;">{{ item.label
                                    }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="redirectDrugs(12)">SEGUIR</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Quadro Clinico -->
            <div v-if="currentValue == 12">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Quadro clínico</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <p class="justified-text">
                        Digite um breve resumo do Quadro
                        Clínico atual dessa(e) paciente:
                    </p>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <textarea class="input-field" v-model="resume" id="resume" name="resume" rows="5"
                            placeholder="Digite aqui"></textarea>
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="redirectResume(13)">SEGUIR</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Solicitação: Resumo -->
            <div v-if="currentValue == 13">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Resumo da sua solicitação:</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Evolução de Paciente
                        </p>
                        <p class="justified-text">
                            Paciente: {{ PatientInfo.name }} - Data de Nascimento: {{
            calculateAge(PatientInfo.birthdate) }}
                        </p>
                    </div>
                </div>
                <div class="content-container">
                    <div class="row mt-3">
                        <p class="justified-text">
                            <b>Febre:</b> {{ renderTrueFalseText(hasFever) }}
                        </p>
                        <p class="justified-text">
                            <b>Frequência cardíaca:</b> {{ freqbpm }} bpm
                        </p>
                        <p class="justified-text">
                            <b>Balanço Hídrico:</b> {{ hydrologicalBalance }} ml
                        </p>
                        <p class="justified-text">
                            <b>Ventilação mecânica:</b> {{ renderTrueFalseText(hasMechanicalVentilation) }}
                        </p>
                        <div v-if="hasMechanicalVentilation">
                            <p class="justified-text">
                                <b>Tipo Ventilação mecânica:</b> {{ hasTypeOfVentilation }}
                            </p>
                        </div>
                        <div v-if="this.hasMechanicalVentilation == false">
                            <p class="justified-text">
                                <b>Tipo de respiração:</b> {{ hasBreathingType }}
                            </p>
                            <div v-if="this.hasBreathingType == 'SUPORTE'">
                                <p class="justified-text">
                                    <b>Tipo de Suporte O2:</b> {{ supportTypeO2 }}
                                </p>
                            </div>
                        </div>
                        <p class="justified-text">
                            <b>Droga vasoativa:</b> {{ renderTrueFalseText(hasVasoactiveDrug) }}
                        </p>
                        <div v-if="hasVasoactiveDrug">
                            <p class="justified-text">
                                <b>Tipo Ventilação mecânica:</b> {{ selectedCheckboxes.join(", ") }}
                            </p>
                        </div>
                        <p class="justified-text">
                            <b>Quadro clínico:</b> {{ resume }}
                        </p>
                    </div>
                </div>
                <div class="mt-3"></div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="finishSolicitation()">CONCLUIR EVOLUÇÃO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(14)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Cancelar Jornada -->
            <div v-if="currentValue == 14">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cancelar</h2>
                    </div>
                </div>
                <div class="row mt-3 justify-content-center">
                    <div class="col-12 col-md-4 d-flex justify-content-center">
                        <img src="../assets/cancel.png" class="img-fluid" width="300" height="300">
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 text-center">
                        <p style="color: #6200EE;"><b>Confirmação</b></p>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <p style="color: #777777;">Tem certeza de deseja cancelar a jornada?</p>
                    </div>
                </div>
                <div class="content-container">
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="previousItem()">CONTINUAR AQUI</button>
                        </div>
                        <div class="attentive-buttons" style="margin-top: 20px">
                            <button class="btn-cancel" @click="backDaily">SAIR</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

class Node {
    constructor(data) {
        this.data = data;
        this.previous = null;
        this.next = null;
    }
}

class LinkedList {
    constructor() {
        this.head = null;
        this.tail = null;
    }

    add(data) {
        const newNode = new Node(data);
        if (!this.head) {
            this.head = newNode;
            this.tail = newNode;
        } else {
            this.tail.next = newNode;
            newNode.previous = this.tail;
            this.tail = newNode;
        }
    }

    removeLast() {
        if (!this.tail) {
            return;
        }
        if (this.head === this.tail) {
            this.head = null;
            this.tail = null;
            return;
        }
        this.tail = this.tail.previous;
        this.tail.next = null;
    }

    toArrayWithNeighbors() {
        let current = this.head;
        const array = [];
        while (current) {
            const item = {
                value: current.data,
                previous: current.previous ? current.previous.data : null,
                next: current.next ? current.next.data : null
            };
            array.push(item);
            current = current.next;
        }
        return array;
    }
}

export default {
    name: `FormAssistance`,
    computed: {
        ...mapGetters({ PatientInfo: "StatePatient", Responsible: "StateResponsible", User: "StateUser", Protocol: "StateProtocol" }),
    },
    mounted() {
        this.termsFormAssistence = false;
        this.selectedState = null;
        this.crm = null;
        this.phone = null;
        this.responsibleInfo = null;
        this.protocol = null;
        this.hasFever = null;
        this.hasGlicemia = null;
        this.freqbpm = null;
        this.hydrologicalBalance = null;
        this.hasMechanicalVentilation = null;
        this.hasTypeOfVentilation = null;
        this.hasBreathingType = null;
        this.supportTypeO2 = null;
        this.hasVasoactiveDrug = null;
        this.selectedCheckboxes = [];
        this.resume = null;
    },
    async created() {
        if (this.isAuthenticated() == null || this.User == null) {
            this.$router.push("/login");
        }
        else if (this.PatientInfo == null) {
            this.$router.push("/daily");
        }
        else {
            this.termsFormAssistence = false;
            this.selectedState = null;
            this.crm = null;
            this.phone = null;
            this.responsibleInfo = null;
            this.protocol = null;
            this.hasFever = null;
            this.hasGlicemia = null;
            this.freqbpm = null;
            this.hydrologicalBalance = null;
            this.hasMechanicalVentilation = null;
            this.hasTypeOfVentilation = null;
            this.hasBreathingType = null;
            this.supportTypeO2 = null;
            this.hasVasoactiveDrug = null;
            this.selectedCheckboxes = [];
            this.resume = null;
            if (this.User.professional_type_id == 2) {
                this.linkedList.add(0);//Termo
                this.currentValue = 0;
            }
            else {
                this.linkedList.add(2);//Solicitação
                this.currentValue = 2;
            }
        }
    },
    data() {
        return {
            linkedList: new LinkedList(),
            currentItem: 0,
            currentObject: null,
            currentValue: 0,
            isLoading: false,
            //Values
            termsFormAssistence: false,
            selectedState: null,
            crm: null,
            phone: null,
            responsibleInfo: null,
            protocol: null,
            hasFever: null,
            hasGlicemia: null,
            freqbpm: null,
            hydrologicalBalance: null,
            hasMechanicalVentilation: null,
            hasTypeOfVentilation: null,
            hasBreathingType: null,
            supportTypeO2: null,
            hasVasoactiveDrug: null,
            selectedCheckboxes: [],
            resume: null,
            //Others     
            states: [
                { code: 'AC', name: 'Acre' },
                { code: 'AL', name: 'Alagoas' },
                { code: 'AP', name: 'Amapá' },
                { code: 'AM', name: 'Amazonas' },
                { code: 'BA', name: 'Bahia' },
                { code: 'CE', name: 'Ceará' },
                { code: 'DF', name: 'Distrito Federal' },
                { code: 'ES', name: 'Espírito Santo' },
                { code: 'GO', name: 'Goiás' },
                { code: 'MA', name: 'Maranhão' },
                { code: 'MT', name: 'Mato Grosso' },
                { code: 'MS', name: 'Mato Grosso do Sul' },
                { code: 'MG', name: 'Minas Gerais' },
                { code: 'PA', name: 'Pará' },
                { code: 'PB', name: 'Paraíba' },
                { code: 'PR', name: 'Paraná' },
                { code: 'PE', name: 'Pernambuco' },
                { code: 'PI', name: 'Piauí' },
                { code: 'RJ', name: 'Rio de Janeiro' },
                { code: 'RN', name: 'Rio Grande do Norte' },
                { code: 'RS', name: 'Rio Grande do Sul' },
                { code: 'RO', name: 'Rondônia' },
                { code: 'RR', name: 'Roraima' },
                { code: 'SC', name: 'Santa Catarina' },
                { code: 'SP', name: 'São Paulo' },
                { code: 'SE', name: 'Sergipe' },
                { code: 'TO', name: 'Tocantins' },
            ],
            checkboxItems: [
                { id: "noradrenalina-checkbox", label: "Noradrenalina" },
                { id: "dobutamina-checkbox", label: "Dobutamina" },
                { id: "dopamina-checkbox", label: "Dopamina" },
                { id: "vasopressina-checkbox", label: "Vasopressina" },
            ],
        };
    },
    methods: {
        ...mapGetters(["isAuthenticated"]),
        ...mapActions(["GetResponsibleCFM"]),
        ...mapActions(["RegisterResponsible"]),
        ...mapActions(["CreateProtocol"]),
        previousItem() {
            if (this.currentItem > 0) {
                this.currentItem--;
                this.currentObject = this.linkedList.toArrayWithNeighbors()[this.currentItem];
                this.currentValue = this.currentObject.value
                this.linkedList.removeLast();
            }
        },
        nextItem() {
            const linkedListLength = this.linkedList.toArrayWithNeighbors().length;
            if (this.currentItem < linkedListLength - 1) {
                this.currentItem++;
                this.currentObject = this.linkedList.toArrayWithNeighbors()[this.currentItem];
                this.currentValue = this.currentObject.value
            }
        },
        renderTrueFalseText(value) {
            if (value) {
                return "Sim"
            }
            else {
                return "Não"
            }
        },
        calculateAge(dataNascimento) {
            if (dataNascimento !== null) {
                var partes = dataNascimento.split(' ');
                var dataPartes = partes[0].split('/');
                var horaPartes = partes.length > 1 ? partes[1].split(':') : [0, 0];
                var dataNasc = new Date(dataPartes[2], dataPartes[1] - 1, dataPartes[0], horaPartes[0], horaPartes[1]);
                var hoje = new Date();
                var idade = hoje.getFullYear() - dataNasc.getFullYear();
                var mes = hoje.getMonth() - dataNasc.getMonth();
                var dia = hoje.getDate() - dataNasc.getDate();
                if (mes < 0 || (mes === 0 && dia < 0)) {
                    idade--;
                }
                partes = dataNascimento.split(' ');
                var dataFormatada = partes[0]
                if (idade < 1) {
                    var meses = (hoje.getMonth() - dataNasc.getMonth()) + (12 * (hoje.getFullYear() - dataNasc.getFullYear()));
                    return dataFormatada + " (" + meses + " Meses)";
                } else {
                    return dataFormatada + " (" + idade + " Anos)";
                }
            }
        },
        validateFreq(event) {
            let value = event.target.value;
            value = value.replace(/\D/g, '');
            if (parseInt(value) > 999) {
                value = '999';
            }
            this.freqbpm = value;
        },
        validateHydrologicalBalance(event) {
            let value = event.target.value;
            value = value.replace(/\D/g, '');
            if (parseInt(value) > 99999) {
                value = '99999';
            }
            this.hydrologicalBalance = value;
        },
        redirectBalance(step) {
            if (this.hydrologicalBalance == null || this.hydrologicalBalance == '') {
                this.$toast.warning('É preciso preencher dados de Balanço Hidrico.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.linkedList.add(step);
                this.nextItem();
            }
        },
        redirectResume(step) {
            if (this.resume == null || this.resume == '') {
                this.$toast.warning('É preciso preencher dados de Quadro Clínico.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.linkedList.add(step);
                this.nextItem();
            }
        },
        redirectDrugs(step) {
            if (this.hasVasoactiveDrug == true && this.selectedCheckboxes.length == 0) {
                this.$toast.warning('É preciso preencher dados de Drogas Vasoativas.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.linkedList.add(step);
                this.nextItem();
            }
        },
        redirectFreqC(step) {
            if (this.freqbpm == null || this.freqbpm == '') {
                this.$toast.warning('É preciso preencher dados de Frequência cardíaca.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.linkedList.add(step);
                this.nextItem();
            }
        },
        redirectStep(step) {
            this.linkedList.add(step);
            this.nextItem();
        },
        handleFeverClick(value, step) {
            this.hasFever = value;
            this.redirectStep(step);
        },
        handleGlicemiaClick(value, step) {
            this.hasGlicemia = value;
            this.redirectStep(step);
        },
        handleMechanicalVentilation(value, step) {
            this.hasMechanicalVentilation = value;
            this.redirectStep(step);
        },
        handleTypeOfVentilation(value, step) {
            this.hasTypeOfVentilation = value;
            this.redirectStep(step);
        },
        handleBreathingType(value, step) {
            this.hasBreathingType = value;
            this.redirectStep(step);
        },
        handleVasoactiveDrug(value, step) {
            this.hasVasoactiveDrug = value;
            this.redirectStep(step);
        },
        redirectStepInTerm() {
            if (this.termsFormAssistence == false || this.termsFormAssistence == null) {
                this.$toast.warning('É preciso aceitar os Termos de responsabilidade para continuar.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.redirectStep(1);
            }
        },
        async validateCFM() {
            if (this.crm == null || this.crm == '') {
                this.$toast.warning('É preciso preencher dados do CRM.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (this.selectedState == null) {
                    this.$toast.warning('É preciso preencher o Estado.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    let responsibleData = {
                        "registration_code": this.crm,
                        "state_code": this.selectedState.code
                    }
                    this.isLoading = true;
                    try {
                        this.responsibleInfo = await this.GetResponsibleCFM(responsibleData)
                        if (this.responsibleInfo != null) {
                            let responsibleRegister = {
                                "professional_code": this.responsibleInfo.registration_code.toString(),
                                "state_code": this.responsibleInfo.state_code,
                                "health_professional_type": "CRM",
                                "name": this.responsibleInfo.name,
                                "phone": this.phone
                            }
                            await this.RegisterResponsible(responsibleRegister)
                            this.redirectStep(2);
                        }
                        else {
                            this.$toast.warning('Informações do médico responsavel incorretas.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                    }
                    catch (erro) {
                        this.$toast.error('Erro ao registrar o responsável.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                    this.isLoading = false;
                }
            }
        },
        backDaily() {
            this.$router.push("/daily");
        },
        async finishSolicitation() {
            if (this.Protocol == null) {
                let protocolInfo = {
                    'patient_id': this.PatientInfo.id,
                    'company_id': this.User.companies_info[0].id,
                    'case_type_id': 1,
                    'case_priority_id': 2,
                    'case_location_id': 3,
                    'specialty_id': 15,
                    'patient_fever': this.hasFever,
                    'patient_fc': this.freqbpm,
                    'patient_balance': this.hydrologicalBalance,
                    'patient_ventilation': this.hasMechanicalVentilation,
                    'patient_ventilation_type': this.hasTypeOfVentilation,
                    'patient_breath': this.hasBreathingType,
                    'patient_air_support': this.supportTypeO2,
                    'patient_vasoactive_type': this.hasVasoactiveDrug,
                    'patient_drugs': this.selectedCheckboxes.join(','),
                    'note': this.resume,
                    'outcome_type_id': 4,
                    'responsible_id': this.Responsible ? this.Responsible.id : this.User.professional_type_id == 1 ? this.User.id : null,
                    'nurse_id': this.User.professional_type_id == 2 ? this.User.id : null
                }
                this.isLoading = true;
                try {
                    await this.CreateProtocol(protocolInfo);
                    this.$toast.success('Protocolo de admissão registrado com sucesso.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                    this.$store.commit('setProtocol', null);
                    this.$store.commit('setPatient', null);
                    this.$router.push("/daily");
                }
                catch (erro) {
                    this.$toast.error('Erro ao criar o protocolo.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }                
                this.isLoading = false;
            }
            else {
                let protocolInfo = {
                    'patient_id': this.PatientInfo.id,
                    'company_id': this.User.companies_info[0].id,
                    'protocol_id': this.Protocol.id,
                    'case_type_id': 2,
                    'case_priority_id': 2,
                    'case_location_id': 3,
                    'patient_fever': this.hasFever,
                    'patient_fc': this.freqbpm,
                    'patient_balance': this.hydrologicalBalance,
                    'patient_ventilation': this.hasMechanicalVentilation,
                    'patient_ventilation_type': this.hasTypeOfVentilation,
                    'patient_breath': this.hasBreathingType,
                    'patient_air_support': this.supportTypeO2,
                    'patient_vasoactive_type': this.hasVasoactiveDrug,
                    'patient_drugs': this.selectedCheckboxes.join(','),
                    'note': this.resume,
                    'responsible_id': this.Responsible ? this.Responsible.id : this.User.professional_type_id == 1 ? this.User.id : null,
                    'nurse_id': this.User.professional_type_id == 2 ? this.User.id : null
                }
                this.isLoading = true;
                try {
                    await this.CreateProtocol(protocolInfo);
                    this.$toast.success('Protocolo de evolução registrado com sucesso.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                    this.$store.commit('setProtocol', null);
                    this.$store.commit('setPatient', null);
                    this.$router.push("/daily");
                }
                catch (erro) {
                    this.$toast.error('Erro ao criar o protocolo.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                this.isLoading = false;
            }            
        },
    }
};
</script>

<style scoped>
@import "vue-select/dist/vue-select.css";

:deep(.v-select) {
    --vs-dropdown-max-height: 200px;
    --vs-border-width: 2px;
    --vs-border-style: solid;
    --vs-border-radius: 25px;
    --vs-border-color: #CCCCCC;
    --vs-dropdown-color: #777777;
    --vs-line-height: 2.0;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}

.input-container {
    position: relative;
    margin-bottom: 20px;
}

.input-field {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline-width: 0;
}

.label-text {
    position: absolute;
    top: -18px;
    left: 10px;
    font-size: 14px;
    background-color: #fff;
    padding: 0 5px;
}

.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}


.style-font-bold {
    color: #777777;
    font-weight: bold;
}

.style-font {
    color: #777777;
}

.button-back {
    background-color: #ffff;
    border: none;
}

.justified-text {
    color: #777777;
    text-align: justify;
}

@media screen and (max-width: 368px) {
    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 80vh;
    }

    .content-container-up {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 120vh;
    }

    .flow-down {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px;
    }

    .justified-text {
        color: #777777;
        text-align: justify;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: normal;
        word-spacing: 0.01em;
    }

    .form-check-input {
        width: 30px;
    }
}

.attentive-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.btn-default {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6200EE;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-cancel {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-noborder {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.custom-select {
    border: 2px solid #CCCCCC;
    border-radius: 25px;
    padding: 5px;
    font-size: 16px;
    color: #777777;
    background-color: white;
}

.custom-select option {
    text-align: center;
}

.custom-select:focus {
    outline: none;
    border-color: #777777;
}
</style>