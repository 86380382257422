<template>
    <div>
        <div v-if="isLoading === true">
            <div class="containermb-5">
                <div class="row">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="centered-container">
                                <div class="loading"></div>
                                <p class="text-font" style="margin-top: 10px; text-align: center">
                                    Carregando...
                                </p>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="container col-lg-6">
                <div v-if="step == 1">
                    <div class="col-12 mt-2 text-center">
                        <img src="../assets/logo-alt.png" alt="Doc4Doc" class="col-6 mb-5" />
                        <div class="offset-1 m-4">
                            <p class="text-center text h5 text-black-50">
                                Para prosseguir digite abaixo seu número de WhatsApp:
                            </p>
                        </div>
                        <div class="col m-4 m">
                            <input type="text" class="form-control" v-model="phone" name="phone"
                                placeholder="(00) 00000-0000" v-mask="'(##) #####-####'">
                        </div>
                        <div class="row flow-down">
                            <div class="attentive-buttons">
                                <button class="btn-default" @click="stepToken()">PRÓXIMO</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="step == 2">
                    <div class="container">
                        <div class="row">
                            <div class="col-2">
                                <button class="button-back mx-3" @click="previousItem()">
                                    <img src="../assets/back-button.png" alt="Button Image">
                                </button>
                            </div>
                            <div class="col-10">
                                <h2 class="style-font-bold">Digite o código de verificação:
                                </h2>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <p class="justified-text">
                                Enviamos um SMS com um código de 4 dígitos para: {{ phone }}. <span
                                    style="margin-left: 2px;" @click="previousItem" class="resend-link">Alterar</span>
                            </p>

                        </div>
                        <div class="row mt-5">
                            <div class="token-input-container">
                                <input class="token-input style-font" type="text" v-model="token[0]" maxlength="1"
                                    @input="focusNext($event, 1)" ref="input0">
                                <input class="token-input style-font" type="text" v-model="token[1]" maxlength="1"
                                    @input="focusNext($event, 2)" ref="input1">
                                <input class="token-input style-font" type="text" v-model="token[2]" maxlength="1"
                                    @input="focusNext($event, 3)" ref="input2">
                                <input class="token-input style-font" type="text" v-model="token[3]" maxlength="1"
                                    @input="focusNext($event, 4)" ref="input3">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="resend-container">
                                <p class="justified-text centered-text" v-if="countdown > 0">Não recebeu? Reenviar
                                    código em
                                    {{ formattedCountdown }}</p>
                                <span v-else @click="resendCode()" class="resend-link">Reenviar código</span>
                            </div>
                        </div>
                        <div class="row flow-down">
                            <div class="attentive-buttons">
                                <button class="btn-default" @click="login()">ENTRAR</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="step == 3">
                    <div class="row">
                        <div class="col-2">
                            <button class="button-back mx-3" @click="previousItem()">
                                <img src="../assets/back-button.png" alt="Button Image">
                            </button>
                        </div>
                        <div class="col-10">
                            <h2 class="style-font-bold">Cadastro</h2>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-4 d-flex justify-content-center">
                            <img src="../assets/whats_journey.png" class="img-fluid" width="300" height="300">
                        </div>
                    </div>
                    <div class="content-container">
                        <div class="row mt-5">
                            <p><b>Cadastro de primeiro acesso.</b></p>
                            <p class="justified-text">
                                Identificamos que seu número ainda não consta em nossa base de dados. Por favor, realize
                                um breve cadastro para poder utilizar nossa plataforma. Será rapidinho!
                            </p>
                        </div>
                        <div class="row flow-down">
                            <div class="attentive-buttons">
                                <button class="btn-default" @click="redirectWhats()">Iniciar Cadastro</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 25px;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";

export default {
    name: `Login`,
    data() {
        return {
            user: store.state.user,
            phone: null,
            token: ['', '', '', ''],
            countdown: 59,
            step: 1,
            isLoading: false,
        }
    },
    computed: {
        formattedCountdown() {
            const minutes = Math.floor(this.countdown / 60);
            const seconds = this.countdown % 60;
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
    },
    methods: {
        ...mapActions(["LogIn"]),
        ...mapActions(["GeneratePin"]),
        ...mapGetters["isAuthenticated"],
        async stepToken() {
            if (this.phone == null || this.phone == "" || this.phone.length < 13) {
                this.$toast.warning('Informe um número de telefone.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.isLoading = true;
                try {
                    await this.GeneratePin(this.phone.replace(/[()\s-]/g, ''));
                    this.step = 2;
                    this.startCountdown();
                }
                catch (erro) {
                    if (erro.response.status == 400) {
                        this.step = 3;
                    }
                    else {
                        this.$toast.error('Ocorreu um erro ao gerar o Pin.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                }
                this.isLoading = false;
            }
        },

        async resendCode() {
            this.isLoading = true
            try {
                await this.GeneratePin(this.phone.replace(/[()\s-]/g, ''));                
                this.step = 2;
                this.countdown = 59;
                this.startCountdown();                
            }
            catch (erro) {
                if (erro.response.status == 400) {
                    this.step = 3;
                }
                else {
                    this.$toast.error('Ocorreu um erro ao gerar o Pin.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
            }
            this.isLoading = false;
        },
        
        startCountdown() {
            this.interval = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.interval);
                }
            }, 1000);
        },
        previousItem() {
            this.step = 1;
        },
        redirectWhats() {
            window.open("https://api.whatsapp.com/send/?phone=551151999990&text=Ol%C3%A1,+quero+realizar+o+meu+cadastro+na+plataforma+da+Doc4doc%21", "_blank");
        },
        focusNext(event, nextIndex) {
            if (event.target.value.length === 1 && nextIndex < 4) {
                this.$refs[`input${nextIndex}`].focus();
            }
        },
        async login() {
            let userInfo = {
                "phone": "55" + this.phone,
                "pin": this.token.join('')
            }
            this.isLoading = true
            try {
                await this.LogIn(userInfo)
                this.$router.push('/')
            } catch (error) {
                if (error.response.status == 401) {
                    this.$toast.warning('Pin Incorreto.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                    this.$router.push('/login')
                }
                else {
                    this.$toast.error('Ocorreu um erro ao realizar o login.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                    this.$router.push('/login')
                }
            }
            this.isLoading = false
        }
    }
}
</script>

<style scoped>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.resend-link {
    color: #6200EE;
    cursor: pointer;
    text-decoration: underline;
}

.token-input-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.token-input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.input-container {
    position: relative;
    margin-bottom: 20px;
}

.input-field {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline-width: 0;
}

.label-text {
    position: absolute;
    top: -18px;
    left: 10px;
    font-size: 14px;
    background-color: #fff;
    padding: 0 5px;
}

.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.style-font-bold {
    color: #777777;
    font-weight: bold;
}

.style-font {
    color: #777777;
}

.button-back {
    background-color: #ffff;
    border: none;
}

.justified-text {
    color: #777777;
    text-align: justify;
}

.resend-container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.centered-text {
    margin: 0 auto;
}

@media screen and (max-width: 368px) {
    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 80vh;
    }

    .content-container-up {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 120vh;
    }

    .flow-down {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px;
    }

    .justified-text {
        color: #777777;
        text-align: justify;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: normal;
        word-spacing: 0.01em;
    }

    .form-check-input {
        width: 40px;
    }
}

.attentive-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.btn-default {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6200EE;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-cancel {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-noborder {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}
</style>
