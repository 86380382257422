<template>
    <div class="container">
        <div v-if="isLoading === true">
            <div class="container mt-5 mb-5">
                <div class="row mt-5">
                    <div class="row mt-4">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="centered-container">
                                <div class="loading"></div>
                            </div>
                            <p class="text-font" style="margin-top: 10px; text-align: center">
                                Carregando...
                            </p>
                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div v-if="User">
                    <h4 class="style-font-user-name">{{ User.name }}</h4>
                    <h5 class="style-font-user-hospital ">{{ User.companies_info[0].name }}</h5>
                </div>
            </div>
            <div class="row mt-5">
                <h4 class="text-font-bold">
                    Selecione a especialidade:
                </h4>
            </div>
            <!--specialtys-->
            <div class="row mt-3" style="cursor: pointer">
                <div class="border-in" @click="redirectDaily()" style="display: flex; align-items: center;">
                    <img src="../assets/ic_uti_neo.png" style="margin-right: 10px;">
                    <div style="display: flex; flex-direction: column;">
                        <h4 class="text-font-bold" style="margin: 0;">UTI Neonatal - Round Diário</h4>
                        <p class="text-font-bold" style="margin: 0;">Disponível agora</p>
                    </div>
                    <div style="display: flex; align-items: center; margin-left: auto;">
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                </div>
            </div>
            <div class="row mt-3" style="cursor: pointer">
                <div class="border-in" @click="redirectRT()" style="display: flex; align-items: center;">
                    <img src="../assets/ic_uti_rt.png" style="margin-right: 10px;">
                    <div style="display: flex; flex-direction: column;">
                        <h4 class="text-font-bold" style="margin: 0;">Teleinterconsulta de Neonatologia</h4>
                        <p class="text-font-bold" style="margin: 0;">Disponível agora</p>
                    </div>
                    <div style="display: flex; align-items: center; margin-left: auto;">
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: `Home`,
    components: {},
    computed: {
        ...mapGetters({ User: "StateUser" }),
    },
    data() {
        return {
            isLoading: false
        };
    },
    methods: {
        ...mapGetters(["isAuthenticated"]),
        redirectDaily() {
            this.$router.push("/daily");
        },
        redirectRT() {
            window.open("https://api.whatsapp.com/send/?phone=551151999990&text=Ol%C3%A1,+eu+preciso+de+falar+com+o+RT+da+UTI%2e", "_blank");
        },
    },
    async created() {
        if (!this.isAuthenticated() || this.User == null) {
            this.$router.push("/login");
        }
    }
};
</script>

<style scoped>
.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}

.btn-default {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6200EE;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.attentive-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.style-font-user-name {
    color: #777777;
    font-weight: bold;
}

.style-font-user-hospital {
    color: #777777;
}

.text-font {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    color: #777777;
}

.text-font-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    color: #777777;
    font-weight: bold;
}

.circle-number {
    width: 50px;
    height: 50px;
    padding: 0;
    background-color: #AE1F38;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
