<template>
    <div class="container">
        <div v-if="isLoading === true">
            <div class="containermb-5">
                <div class="row">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="centered-container">
                                <div class="loading"></div>
                                <p class="text-font" style="margin-top: 10px; text-align: center">
                                    Carregando...
                                </p>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <!-- Termo -->
            <div v-if="currentValue == 0">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="backDaily()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-8">
                        <h2 class="style-font-bold">Termo</h2>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Eu {{ User.name }}, declaro ter realizado o atendimento médico do paciente, registrando
                        todas as
                        informações necessárias sobre o atendimento, decidindo pelo seu encaminhamento para
                        teleinterconsulta médica especializada.
                    </p>
                    <p class="justified-text">
                        Declaro que procedi com as devidas orientações do
                        paciente e/ou de seu acompanhante sobre o atendimento, esclarecendo, ainda, todas as dúvidas
                        sobre a realização e encaminhamento para a teleinterconsulta médica especializada.
                    </p>
                    <p class="justified-text">
                        Declaro,
                        ainda, minha responsabilidade exclusiva sobre o tratamento prescrito após a
                        teleinterconsulta
                        realizada por médico especialista da Doc4Doc.
                    </p>
                </div>
                <div class="row mt-2">
                    <div class="col-2"></div>
                    <div class="col-8">
                        <div class="form-check d-flex justify-content-center align-items-center">
                            <input type="checkbox" style="margin-right: 10px;" class="form-check-input"
                                id="termsCheckbox" v-model="termsAccepted">
                            <label class="form-check-label justified-text" for="termsCheckbox">Li, concordo e aceito
                                todos os termos e condições de uso.</label>
                        </div>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="row flow-down ">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="redirectStepInTerm">PRÓXIMO</button>
                    </div>
                </div>
            </div>
            <!-- Dados Paciente: Tipo Paciente -->
            <div v-if="currentValue == 1">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro de paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Selecione uma das opções abaixo para cadastrar o paciente:
                    </p>
                </div>
                <div class="row mt-3" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(2)" style="display: flex; align-items: center;">
                        <img src="../assets/pacient_cpf.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Criança com CPF </h4>
                            <p class="style-font" style="margin: 0;">Tenha em mãos o CPF do paciente</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(5)" style="display: flex; align-items: center;">
                        <img src="../assets/pacient_not_cpf.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Criança sem CPF (CPF da mãe)</h4>
                            <p class="style-font" style="margin: 0;">Tenha em mãos o CPF da mãe</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(9)" style="display: flex; align-items: center;">
                        <img src="../assets/paciente_not_data.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Criança sem documentação</h4>
                            <p class="style-font" style="margin: 0;">Exclusivo para indígenas, estrangeiros...</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Paciente: Paciente possue CPF  -->
            <div v-if="currentValue == 2">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Digite abaixo o CPF do paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Digite o CPF abaixo. Se não estiver na base, será necessário realizar um breve cadastro.
                    </p>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="cpf">Digite o CPF do paciente:</label>
                        <input class="input-field" type="text" v-model="cpf" id="cpf" name="cpf" maxlength="14"
                            placeholder="000.000.000-00" v-mask="'###.###.###-##'">
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="verifyPatientCPF()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Paciente: Preencher Cadastro CPF Paciente -->
            <div v-if="currentValue == 3">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro de paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Por favor, faça um breve cadastro deste paciente.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="cpf">Digite abaixo seu CPF do paciente:</label>
                        <input class="input-field" type="text" v-model="cpf" id="cpf" name="cpf" maxlength="14"
                            placeholder="000.000.000-00" v-mask="'###.###.###-##'">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDate">Digite abaixo data de nascimento da
                            paciente:</label>
                        <input class="input-field" type="text" v-model="birthDate" id="birthDate" name="date"
                            placeholder="00/00/0000" v-mask="'##/##/####'" />
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="registryPatientCPF()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Paciente: CPF mãe Paciente  -->
            <div v-if="currentValue == 5">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Digite abaixo o CPF da Mãe</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Digite o CPF abaixo. Se não estiver na base, será necessário realizar um breve cadastro.
                    </p>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="cpfMother">Digite o CPF da mãe:</label>
                        <input class="input-field" type="text" v-model="cpfMother" id="cpfMother" name="cpfMother"
                            maxlength="14" placeholder="000.000.000-00" v-mask="'###.###.###-##'">
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="verifyPatientMotherCPF()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Paciente: Preencher Cadastro Mãe CPF Paciente -->
            <div v-if="currentValue == 6">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro de paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Por favor, realize um breve cadastro e tenha em mãos os dados da mãe como data de nascimento
                            e número de CPF
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="cpfMother">Digite o CPF da mãe:</label>
                        <input class="input-field" type="text" v-model="cpfMother" id="cpfMother" name="cpfMother"
                            maxlength="14" placeholder="000.000.000-00" v-mask="'###.###.###-##'">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDateMother">Digite a data de nascimento da
                            mãe:</label>
                        <input class="input-field" type="text" v-model="birthDateMother" id="birthDateMother"
                            name="date" placeholder="00/00/0000" v-mask="'##/##/####'" />
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="registryPatientMotherCPF()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Paciente: Informação Criança -->
            <div v-if="currentValue == 7">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Paciente cadastrado</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Paciente: {{ PatientMother.name }} - Idade: {{ calculateAge(PatientMother.birthdate) }}
                        </p>
                    </div>
                </div>
                <div v-if="Childrens.length > 0">
                    <div class="row mt-2">
                        <div>
                            <p class="justified-text">
                                Selecione uma criança para admitir:
                            </p>
                        </div>
                    </div>
                </div>
                <div v-for="(children) in Childrens" :key="children.id" class="row mt-2">
                    <div class="row mt-3" style="cursor: pointer">
                        <div class="border-in" @click="redirectAssistanceChildren(children)"
                            style="display: flex; align-items: center;">
                            <img src="../assets/ic_child.png" style="margin-right: 10px;">
                            <div style="display: flex; flex-direction: column;">
                                <h4 class="text-font-bold" style="margin: 0;">{{ children.name }}</h4>
                                <p class="text-font-bold" style="margin: 0;">{{ children.birthdate }}</p>
                            </div>
                            <div style="display: flex; align-items: center; margin-left: auto;">
                                <font-awesome-icon :icon="['fas', 'chevron-right']" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <button class="btn-noborder" style="cursor: pointer" @click="redirectStep(8)">+ ADICIONAR OUTRA
                        CRIANÇA</button>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Paciente: Preencher Cadastro criança CPF mãe -->
            <div v-if="currentValue == 8">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Adicionar criança</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Esta opção é exclusiva para adicionar uma criança vinculada à mesma mãe.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="nameChildren">Digite o nome da criança:</label>
                        <input class="input-field" type="text" v-model="nameChildren" id="nameChildren"
                            name="nameChildren">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDateChildren">Digite a data de nascimento da
                            criança:</label>
                        <input class="input-field" type="text" v-model="birthDateChildren" id="birthDateChildren"
                            name="date" placeholder="00/00/0000" v-mask="'##/##/####'" />
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="registryChildrenMother()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Dados Paciente: Paciente e Mãe sem CPF -->
            <div v-if="currentValue == 9">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Paciente sem CPF</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4 d-flex justify-content-center">
                        <img src="../assets/jornadarg.png" class="img-fluid" width="300" height="300">
                    </div>
                </div>
                <div class="content-container">
                    <div class="row mt-5">
                        <p><b>Termo de Responsabilidade para Cadastro de Paciente sem CPF</b></p>
                        <p class="justified-text">
                            Conforme estabelecido pela Resolução CFM nº 2.314/2022, que regulamenta a telemedicina no
                            Brasil, incluindo a teleinterconsulta, é fundamental assegurar a identificação inequívoca do
                            paciente. Esta identificação, muitas vezes realizada por meio do CPF, tem como objetivo
                            garantir a correta associação das informações médicas ao paciente, evitando erros de
                            identificação e assegurando a integridade do seu histórico médico.
                        </p>
                        <p class="justified-text">
                            Entretanto, reconhecemos que pacientes em situações específicas, como indígenas e
                            estrangeiros sem documentação brasileira podem não possuir CPF ou documentos de
                            identificação adequados. Por isso, estamos permitindo o cadastro utilizando apenas o nome, a
                            data de nascimento e o nome da mãe. No entanto, é importante ressaltar que esta abordagem
                            pode apresentar riscos adicionais, incluindo a potencial infração à Resolução CFM e
                            possíveis dificuldades na identificação correta do paciente e na integração das informações
                            de saúde.
                        </p>
                        <p class="justified-text">
                            Ao prosseguir com o cadastro de interconsulta sem a utilização do CPF, o solicitante declara
                            estar ciente dos riscos envolvidos e assume a total responsabilidade pela precisão das
                            informações fornecidas e pelas penalidades que podem ser adotadas pelas autoridades
                            reguladoras e instituições de saúde.
                        </p>
                    </div>
                    <div class="row mt-2">
                        <div>
                            <div class="form-check d-flex justify-content-center align-items-center">
                                <input type="checkbox" style="margin-right: 10px;" class="form-check-input-new"
                                    id="termsCheckbox" v-model="termsCheckbox">
                                <label class="form-check-label justified-text" for="termsCheckbox">Declaro estar
                                    plenamente ciente dos riscos envolvidos ao solicitar uma teleinterconsulta para o
                                    paciente, os quais incluem, mas não se limitam, às possíveis penalidades decorrentes
                                    da infração à Resolução CFM 2.314/2022, bem como imprecisões no histórico médico do
                                    paciente.</label>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 80px;"></div>
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="redirectFormStep(10)">PRÓXIMO</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Dados Paciente: Preencher Cadastro Paciente indigente -->
            <div v-if="currentValue == 10">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro
                        </h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Esta jornada é exclusiva para crianças sem CPF, que estejam acompanhadas por pais indígenas,
                            pessoas em situação de rua, estrangeiros ou em situações similares, nas quais não haja
                            acesso ao CPF brasileiro.
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="input-container">
                        <label class="label-text justified-text" for="nameChildren">Digite o nome da criança:</label>
                        <input class="input-field" type="text" v-model="nameChildren" id="nameChildren"
                            name="nameChildren" maxlength="50">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDateChildren">Digite abaixo data de
                            nascimento da
                            criança:</label>
                        <input class="input-field" type="text" v-model="birthDateChildren" id="birthDateChildren"
                            name="birthDateChildren" placeholder="00/00/0000" v-mask="'##/##/####'" />
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="nameMother">Nome da mãe:</label>
                        <input class="input-field" type="text" v-model="nameMother" id="nameMother" name="nameMother"
                            maxlength="50">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDate">Digite abaixo data de nascimento da
                            mãe:</label>
                        <input class="input-field" type="text" v-model="birthDate" id="birthDate" name="birthDate"
                            placeholder="00/00/0000" v-mask="'##/##/####'" />
                    </div>
                </div>
                <div style="margin-top: 120px;"></div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="registerPatientAndMotherNotCPF()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>
            <!-- Cancelar Jornada -->
            <div v-if="currentValue == 11">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cancelar</h2>
                    </div>
                </div>
                <div class="row mt-3 justify-content-center">
                    <div class="col-12 col-md-4 d-flex justify-content-center">
                        <img src="../assets/cancel.png" class="img-fluid" width="300" height="300">
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 text-center">
                        <p style="color: #6200EE;"><b>Confirmação</b></p>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <p style="color: #777777;">Tem certeza de deseja cancelar a jornada?</p>
                    </div>
                </div>
                <div class="content-container">
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="previousItem()">CONTINUAR AQUI</button>
                        </div>
                        <div class="attentive-buttons" style="margin-top: 20px">
                            <button class="btn-cancel" @click="backDaily()">SAIR</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 15px;"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

class Node {
    constructor(data) {
        this.data = data;
        this.previous = null;
        this.next = null;
    }
}

class LinkedList {
    constructor() {
        this.head = null;
        this.tail = null;
    }

    add(data) {
        const newNode = new Node(data);
        if (!this.head) {
            this.head = newNode;
            this.tail = newNode;
        } else {
            this.tail.next = newNode;
            newNode.previous = this.tail;
            this.tail = newNode;
        }
    }

    removeLast() {
        if (!this.tail) {
            return;
        }
        if (this.head === this.tail) {
            this.head = null;
            this.tail = null;
            return;
        }
        this.tail = this.tail.previous;
        this.tail.next = null;
    }

    toArrayWithNeighbors() {
        let current = this.head;
        const array = [];
        while (current) {
            const item = {
                value: current.data,
                previous: current.previous ? current.previous.data : null,
                next: current.next ? current.next.data : null
            };
            array.push(item);
            current = current.next;
        }
        return array;
    }
}

export default {
    name: `FormRegister`,
    computed: {
        ...mapGetters({ Patient: "StatePatient", PatientMother: "StatePatientMother", User: "StateUser", Childrens: "StateChildrens" }),
    },
    data() {
        return {
            //Journey            
            linkedList: new LinkedList(),
            currentItem: 0,
            currentObject: null,
            currentValue: 0,
            isLoading: false,
            //Others
            cpf: "",
            cpfMother: "",
            birthDate: "",
            birthDateMother: "",
            nameChildren: null,
            nameMother: null,
            birthDateChildren: null,
            termsAccepted: false,
            termsCheckbox: false,
        };
    },
    async created() {
        if (!this.isAuthenticated() || this.User == null) {
            this.$router.push("/login");
        }
        else {
            this.linkedList.add(0);//Termo
        }
    },
    methods: {
        ...mapActions(["GetPatient"]),
        ...mapActions(["ValidationDocument"]),
        ...mapActions(["RegisterPatient"]),
        ...mapActions(["GetPatientById"]),
        ...mapActions(["GetChildrens"]),
        ...mapGetters(["isAuthenticated"]),
        previousItem() {
            if (this.currentItem > 0) {
                this.currentItem--;
                this.currentObject = this.linkedList.toArrayWithNeighbors()[this.currentItem];
                this.currentValue = this.currentObject.value
                this.linkedList.removeLast();
            }
        },
        nextItem() {
            const linkedListLength = this.linkedList.toArrayWithNeighbors().length;
            if (this.currentItem < linkedListLength - 1) {
                this.currentItem++;
                this.currentObject = this.linkedList.toArrayWithNeighbors()[this.currentItem];
                this.currentValue = this.currentObject.value
            }
        },
        redirectStepInTerm() {
            if (this.termsAccepted == false || this.termsAccepted == null) {
                this.$toast.warning('É preciso aceitar os Termos e condições de Uso para continuar.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.redirectStep(1);
            }
        },
        async verifyPatientCPF() {
            if (!this.validateCPF(this.cpf)) {
                this.$toast.warning('CPF inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.isLoading = true;
                await this.GetPatient(this.cpf)
                this.isLoading = false;
                if (this.Patient) {//Tem CPF Cadastrado
                    this.redirectAssistance();
                }
                else {//Não tem CPF Cadastro
                    this.redirectStep(3)
                }
            }
        },
        async registryPatientCPF() {
            if (!this.validateCPF(this.cpf)) {
                this.$toast.warning('CPF inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (!this.validateDate(this.birthDate)) {
                    this.$toast.warning('Data de Nascimento Incorreta.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    let patientData = {
                        "cpf": this.cpf,
                        "birthDate": this.birthDate
                    }
                    this.isLoading = true;
                    let result = await this.ValidationDocument(patientData);
                    this.isLoading = false;
                    if (result) {
                        const dateAPI = new Date(result.birthdate);
                        const [day, month, year] = this.birthDate.split('/');
                        const dateForm = new Date(`${year}-${month}-${day}`);
                        const dateAPIStr = dateAPI.toISOString().split('T')[0];
                        const dateFormStr = dateForm.toISOString().split('T')[0];
                        if (dateAPIStr !== dateFormStr) {
                            this.$toast.warning('Data de Nascimento Incorreta.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                        else {
                            this.isLoading = true;
                            try {
                                const day = String(dateAPI.getDate()).padStart(2, '0');
                                const month = String(dateAPI.getMonth() + 1).padStart(2, '0');
                                const year = dateAPI.getFullYear();
                                const formattedDate = `${day}/${month}/${year}`;
                                const patientInfo = {
                                    "document": result.document.replace(/[.-]/g, ''),
                                    "document_type": "CPF",
                                    "birthdate": formattedDate,
                                    "name": result.name,
                                    "document_issuer": null,
                                    "phone": null
                                }

                                await this.RegisterPatient(patientInfo)

                                this.redirectAssistance();
                            }
                            catch (erro) {
                                this.$toast.error('Erro ao cadastrar paciente.', {
                                    timeout: 3000,
                                    closeOnClick: true
                                });
                            }
                            this.isLoading = false;
                        }
                    }
                    else {
                        this.$toast.warning('Dados incorretos.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                }
            }
        },
        async verifyPatientMotherCPF() {
            if (!this.validateCPF(this.cpfMother)) {
                this.$toast.warning('CPF inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.isLoading = true;
                await this.GetPatient(this.cpfMother)
                if (this.Patient) {//Tem CPF Cadastrado
                    this.$store.commit('setPatientMother', this.Patient);
                    if (this.PatientMother != null) {
                        await this.GetChildrens(this.PatientMother)
                    }
                    this.redirectStep(7)
                }
                else {//Não tem CPF Cadastro
                    this.redirectStep(6)
                }
                this.isLoading = false;
            }
        },
        async registryPatientMotherCPF() {
            if (!this.validateCPF(this.cpfMother)) {
                this.$toast.warning('CPF inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (!this.validateDate(this.birthDateMother)) {
                    this.$toast.warning('Data de Nascimento Incorreta.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    let patientData = {
                        "cpf": this.cpfMother,
                        "birthDate": this.birthDateMother
                    }
                    this.isLoading = true;
                    let result = await this.ValidationDocument(patientData);
                    this.isLoading = false;
                    if (result) {
                        const dateAPI = new Date(result.birthdate);
                        const [day, month, year] = this.birthDateMother.split('/');
                        const dateForm = new Date(`${year}-${month}-${day}`);
                        const dateAPIStr = dateAPI.toISOString().split('T')[0];
                        const dateFormStr = dateForm.toISOString().split('T')[0];
                        if (dateAPIStr !== dateFormStr) {
                            this.$toast.warning('Data de Nascimento Incorreta.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                        else {
                            this.isLoading = true;
                            try {
                                const day = String(dateAPI.getDate()).padStart(2, '0');
                                const month = String(dateAPI.getMonth() + 1).padStart(2, '0');
                                const year = dateAPI.getFullYear();
                                const formattedDate = `${day}/${month}/${year}`;
                                const patientInfo = {
                                    "document": result.document.replace(/[.-]/g, ''),
                                    "document_type": "CPF",
                                    "birthdate": formattedDate,
                                    "name": result.name,
                                    "document_issuer": null,
                                    "phone": null
                                }
                                await this.RegisterPatient(patientInfo)
                                this.$store.commit('setPatientMother', this.Patient);
                                if (this.PatientMother != null) {
                                    await this.GetChildrens(this.PatientMother)
                                }
                                this.redirectStep(7)
                            }
                            catch (erro) {
                                this.$toast.error('Erro ao cadastrar paciente.', {
                                    timeout: 3000,
                                    closeOnClick: true
                                });
                            }
                            this.isLoading = false;
                        }
                    }
                    else {
                        this.$toast.warning('Dados incorretos.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                }
            }
        },
        async registryChildrenMother() {
            if (this.nameChildren == null || this.nameChildren == '') {
                this.$toast.warning('Preencha o nome.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (!this.validateDate(this.birthDateChildren)) {
                    this.$toast.warning('Data de Nascimento Incorreta.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    const patientInfo = {
                        "document": null,
                        "document_type": null,
                        "birthdate": this.birthDateChildren,
                        "name": this.nameChildren,
                        "document_issuer": null,
                        "phone": null,
                        "patient_id": this.PatientMother.id
                    }
                    this.isLoading = true;
                    await this.RegisterPatient(patientInfo)
                    if (this.PatientMother != null) {
                        await this.GetChildrens(this.PatientMother)
                    }
                    this.redirectStep(7);
                    this.isLoading = false;
                }
            }
        },
        redirectAssistanceChildren(children) {
            this.$store.commit("setPatient", children);
            this.$router.push("/assistance");
        },
        redirectFormStep(step) {
            if (this.termsCheckbox == false || this.termsCheckbox == null) {
                this.$toast.warning('É preciso aceitar os Termos e condições de Uso para continuar.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            this.linkedList.add(step);
            this.nextItem();
        },
        async registerPatientAndMotherNotCPF() {
            if (this.nameMother == null || this.nameMother == "") {
                this.$toast.warning('Preencha o nome da mãe.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (this.nameChildren == null || this.nameChildren == "") {
                    this.$toast.warning('Preencha o nome da criança.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    if (!this.validateDate(this.birthDateChildren)) {
                        this.$toast.warning('Data de Nascimento Criança Incorreta.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                    else {
                        if (!this.validateDate(this.birthDate)) {
                            this.$toast.warning('Data de Nascimento Mãe Incorreta.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                        else {
                            const patientInfoMother = {
                                "document": null,
                                "document_type": null,
                                "birthdate": this.birthDate,
                                "name": this.nameMother,
                                "document_issuer": null,
                                "phone": null,
                            }
                            this.isLoading = true;
                            await this.RegisterPatient(patientInfoMother)
                            const patientInfoChildren = {
                                "document": null,
                                "document_type": null,
                                "birthdate": this.birthDateChildren,
                                "name": this.nameChildren,
                                "document_issuer": null,
                                "phone": null,
                                "patient_id": this.Patient.id
                            }
                            await this.RegisterPatient(patientInfoChildren)
                            this.isLoading = false;
                            this.redirectAssistance();
                        }
                    }
                }
            }
        },
        redirectStep(step) {
            this.linkedList.add(step);
            this.nextItem();
        },
        calculateAge(dataNascimento) {
            if (dataNascimento !== null) {
                var partes = dataNascimento.split(' ');
                var dataPartes = partes[0].split('/');
                var horaPartes = partes.length > 1 ? partes[1].split(':') : [0, 0];
                var dataNasc = new Date(dataPartes[2], dataPartes[1] - 1, dataPartes[0], horaPartes[0], horaPartes[1]);
                var hoje = new Date();
                var idade = hoje.getFullYear() - dataNasc.getFullYear();
                var mes = hoje.getMonth() - dataNasc.getMonth();
                var dia = hoje.getDate() - dataNasc.getDate();
                if (mes < 0 || (mes === 0 && dia < 0)) {
                    idade--;
                }
                partes = dataNascimento.split(' ');
                var dataFormatada = partes[0]
                if (idade < 1) {
                    var meses = (hoje.getMonth() - dataNasc.getMonth()) + (12 * (hoje.getFullYear() - dataNasc.getFullYear()));
                    return dataFormatada + " (" + meses + " Meses)";
                } else {
                    return dataFormatada + " (" + idade + " Anos)";
                }
            }
        },
        validateCPF(cpf) {
            cpf = cpf.replace(/\D/g, '');
            if (cpf.length !== 11) return false;
            if (/^(\d)\1{10}$/.test(cpf)) return false;
            let sum = 0;
            let remainder;
            for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
            remainder = (sum * 10) % 11;
            if ((remainder === 10) || (remainder === 11)) remainder = 0;
            if (remainder !== parseInt(cpf.substring(9, 10))) return false;
            sum = 0;
            for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
            remainder = (sum * 10) % 11;
            if ((remainder === 10) || (remainder === 11)) remainder = 0;
            if (remainder !== parseInt(cpf.substring(10, 11))) return false;
            return true;
        },
        validateDate(data) {
            const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
            if (!regex.test(data)) {
                return false;
            }
            const partes = data.split('/');
            const dia = parseInt(partes[0], 10);
            const mes = parseInt(partes[1], 10) - 1;
            const ano = parseInt(partes[2], 10);
            const dataObjeto = new Date(ano, mes, dia);
            if (dataObjeto.getFullYear() !== ano || dataObjeto.getMonth() !== mes || dataObjeto.getDate() !== dia) {
                return false;
            }
            return true;
        },
        backDaily() {
            this.$router.push("/daily");
        },
        redirectAssistance() {
            this.$router.push("/assistance");
        },
    }
};
</script>

<style scoped>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}

.input-container {
    position: relative;
    margin-bottom: 20px;
}

.input-field {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline-width: 0;
}

.label-text {
    position: absolute;
    top: -18px;
    left: 10px;
    font-size: 14px;
    background-color: #fff;
    padding: 0 5px;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.style-font-bold {
    color: #777777;
    font-weight: bold;
}

.style-font {
    color: #777777;
}

.button-back {
    background-color: #ffff;
    border: none;
}

.justified-text {
    color: #777777;
    text-align: justify;
}

@media screen and (max-width: 368px) {
    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 80vh;
    }

    .content-container-up {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 120vh;
    }

    .flow-down {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px;
    }

    .justified-text {
        color: #777777;
        text-align: justify;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: normal;
        word-spacing: 0.01em;
    }

    .form-check-input {
        width: 30px;
    }

    .form-check-input-new {
        width: 60px;
    }
}

.attentive-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.btn-default {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6200EE;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-cancel {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-noborder {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}
</style>
