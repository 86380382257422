import {createStore} from "vuex";
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
//import axios from "axios";

const store = createStore({
    state,
    actions,
    mutations,
    getters
})

export default store;