import Home from './views/Home.vue'
import Login from './views/Login.vue'
import FormPacient from './views/FormPacient.vue'
import FormAssistance from './views/FormAssistance.vue'
import DailyRoutine from './views/DailyRoutine.vue'

import { createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        name: 'Login',
        component:Login,
        path:'/login',
        meta: {
            hideNavbar: true,
        }
    },
    {
        path: "/form",
        name: "FormPacient",
        component: FormPacient,
    },  
    {
        path: "/assistance",
        name: "FormAssistance",
        component: FormAssistance,
    },
    {
        path: "/daily",
        name: "DailyRoutine",
        component: DailyRoutine,
    }  
];

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router