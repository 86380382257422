export default {
    isAuthenticated(state) {
        const user = localStorage.getItem('user-flow');
        if (user !== null) {
            state.user = JSON.parse(user);
            return state.user;
        }
        return null;
    },
    StateDailyCases: (state) => state.dailyCases,
    StateEvolutionCases: (state) => state.evolutionCases,
    StateGeneralCases: (state) => state.generalCases,
    StatePatient: (state) => state.patient,
    StatePatientMother: (state) => state.patientMother,
    StateProtocol: (state) => state.protocol,
    StateChildrens: (state) => state.childrens,
    StateResponsible: (state) => state.responsible,
    StateOutcomes: (state) => state.outcome,    
    StateUser: (state) => state.user,
}