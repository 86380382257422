import axios from 'axios'
import store from "@/store/index";

export default {

    async LogOut({ commit }) {
        commit("logout", null);
    },

    async LogIn({ commit }, User) {
        await axios.post('/flow/login', User)
            .then(function (response) {
                commit('setUser', { ...response.data.data.health_professional_info, token: response.data.data.token })
            })
            .catch(error => {
                console.error(error);
                throw error
            });
    },

    async GeneratePin(_, phone) {
        await axios.post('/flow/generate-pin', {
            "phone": "55" + phone
        })
            .then(function (response) {
                if (response.status == 200) {
                    return true
                }
                else {
                    return false
                }
            })
            .catch(error => {
                console.error(error);
                throw error
            });
    },

    async ValidationDocument(_, patientData) {
        let user = store.state.user
        let body = {
            "number": patientData.cpf.replace(/[.-]/g, ''),
            "birthdate": patientData.birthDate
        };
        try {
            let response = await axios.post('/validation/cpf', body, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status == 200) {
                return response.data.data.person_info;
            }
            else {
                return null;
            }
        } catch (error) {
            console.error("Erro ao validar o cpf", error);
            return null;
        }
    },

    async CreateProtocol(_, body) {
        let user = store.state.user
        try {
            await axios.post('/protocol_update/create',
                body,
                {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
        } catch (error) {
            console.error("Erro ao criar o protocolo", error);
            throw error
        }
    },

    async RegisterResponsible({ commit }, body) {
        let user = store.state.user
        try {
            let response = await axios.post('/responsible/register',
                body,
                {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            commit("setResponsible", response.data.data.health_professional_info);
        } catch (error) {
            console.error("Erro ao registrar o responsável", error);
        }
    },

    async GetResponsibleCFM(_, body) {
        let user = store.state.user
        try {
            let response = await axios.post('/search/cfm', body, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                }
            });            
            if (response.status == 200) {
                return response.data.data.health_professional_info
            }
            else {
                return null
            }
        } catch (error) {
            console.error("Erro ao obter as informações de CFM", error);
            return null
        }
    },

    async GetPatientById({ commit }, patientId) {
        let user = store.state.user
        try {
            let response = await axios.post('/patient/get', { patient_id: patientId }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                }
            });
            commit("setPatient", response.data.data.patient_info);
        } catch (error) {
            console.error("Erro ao obter as informações do paciente", error);
        }
    },

    async RegisterPatient({ commit }, patientInfo) {
        let user = store.state.user
        try {
            let response = await axios.post('/patient/register', patientInfo, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                }
            });
            commit("setPatient", response.data.data.patient_info);
        } catch (error) {
            console.error("Erro ao registrar o paciente", error);
            throw error
        }
    },

    async GetChildrens({ commit }, patient) {
        let user = store.state.user
        let body = {
            "patient_id": patient.id
        };
        try {
            let response = await axios.post("/patient/children", body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setChildren", response.data.data.patients_info);
        } catch (error) {
            console.error("Erro ao buscar o paciente:", error);
        }
    },

    async GetPatient({ commit }, cpf) {
        let user = store.state.user
        let body = {
            "document_number": cpf.replace(/[.-]/g, ''),
            "document_type": "CPF"
        };
        try {
            let response = await axios.post("/patient/document", body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setPatient", response.data.data.patient_info);
        } catch (error) {
            console.error("Erro ao buscar o paciente:", error);
        }
    },

    async GetEvolutionCases({ commit }) {
        let user = store.state.user        
        let body = {
            "company_code": user.companies_info[0].code,
            "outcome_type_ids": [4],
            "specialty_id": "15",
            "not_updated": 0
        };
        try {
            let response = await axios.post("/protocol/list", body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });            
            commit("setEvolutionCases", response.data.data.protocols_info);
        } catch (error) {
            console.error("Erro ao buscar os casos em evolução:", error);
        }
    },

    async GetDailyCases({ commit }) {
        let user = store.state.user        
        let body = {
            "company_code": user.companies_info[0].code,
            "outcome_type_ids": [4],
            "specialty_id": "15",
            "not_updated": 1
        };
        try {
            let response = await axios.post("/protocol/list", body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setDailyCases", response.data.data.protocols_info);
        } catch (error) {
            console.error("Erro ao buscar os casos diários:", error);
        }
    },

    async GetDailyGeneralCases({ commit }) {
        let user = store.state.user
        let body = {
            "company_code": user.companies_info[0].code,
            "outcome_type_ids": null,
            "specialty_id": "15",
            "not_updated": 0
        };
        try {
            let response = await axios.post("/protocol/list", body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setDailyGeneralCases", response.data.data.protocols_info);
        } catch (error) {
            console.error("Erro ao buscar os casos diários:", error);
        }
    },

    async GetOutcomeResource({ commit }) {
        let user = store.state.user
        try {
            let response = await axios.get("/resource/outcome", {}, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setOutcome", response.data.data.outcomes_info);
        } catch (error) {
            console.error("Erro ao buscar o recurso de desfecho:", error);
        }
    },

    async CloseProtocol(_, patientData) {
        let user = store.state.user
        let body = {
            "protocol_id": patientData.id,
            "outcome_type_id": patientData.outcome,
            "overwrite": false
        };
        try {
            let response = await axios.post('/protocol/close', body,
                {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.status
        } catch (error) {
            return error.response.status
        }
    },    
}